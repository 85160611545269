@import '../../../../../theme/main';

.menu-tabs-wrapper {
  background: $gray-bg;
  border-radius: 10px 10px 0 0;
  margin-top: 25px;

  .tabs-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-bottom: 0.5px solid $gray-border;

    &.collapse-tabs {
      background: $white;
      flex-direction: column;
      border-bottom: 0;
    }

    .tab-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;
      height: 110px;

      &:first-child {
        border-radius: 10px 0 0 0;
      }
      &:last-child {
        border-radius: 0 10px 0 0;
      }

      &.selected {
        border-bottom: 4px solid $red;
        background: $white;
      }

      &.normal {
        width: 100%;
      }

      .icon {
        &-urba {
          img {
            height: 72px;
          }
        }
        img {
          height: 42px;
        }
      }

      .label {
        margin-top: 10px;
        color: $color-primary;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .connector-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
  }
}
