@import '../../../../theme/main';

.tab-urbanease-academy {
  .tab-inner {
    display: block;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: $light-purple;
      margin-top: 0;
    }
  }
  .tab-4-body {
    .slider {
      display: flex;
      overflow-y: scroll;
      align-items: center;
      .slide {
        flex: 0 0 50%; // Default: Show 2 slides on larger screens
        min-width: 50%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;

        h4 {
          font-size: 22px;
          line-height: 29px;
          color: $light-purple;
          margin: 0px 0px 4px 30px;
          min-height: 60px;
        }

        .video {
          width: 100%;
          height: 250px;
          background: #f5f5f5;
          border-radius: 30px;
          iframe {
            border-radius: 30px;
          }
          .react-player__preview {
            border-radius: 30px;
          }
        }
      }

      @media (max-width: 768px) {
        .slide {
          flex: 0 0 100%; // On mobile, only 1 slide visible
          min-width: 100%;
        }
      }

      &-actions {
        display: flex;
        justify-content: center;
        align-content: center;
        margin: 10px;
        svg {
          background: $color-primary;
          width: 40px;
          height: 40px;
          color: #ffffff;
          border-radius: 50%;
          padding: 6px;
          margin: 10px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    h2 {
      font-style: normal;
      font-weight: 800;
      color: $light-purple;
      margin: 35px 0px 25px 0px;
    }
    .accordian {
      font-style: normal;
      color: $light-purple;
      box-shadow: 0px;
      p {
        line-height: 20px;
      }
      a {
        color: $light-purple;
      }
      .MuiTypography-body1 {
        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        color: $light-purple;
      }
      .MuiSvgIcon-root {
        color: $light-purple;
      }
      .MuiAccordionSummary-root {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
